<template>
  <div>
    <div class="pa-4">
      <navbar-profile :items="items"></navbar-profile>
    </div>
    <div class="px-5">
      <v-row no-gutters class="mt-4" align="center">
        <v-col cols="12" md="4" class="py-2">
          <bullet :title="'List All Document'" />
        </v-col>
        <v-col cols="12" md="8">
          <v-row no-gutters align="center" justify="end">
            <div class="col_tollbar d-flex align-center">
              <h4 class="mr-2">Show</h4>
              <v-menu
                v-model="menuLimit"
                elevation="0"
                transition="slide-y-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    min-width="60px"
                    max-width="60px"
                    outlined
                    depressed
                    class="text-capitalize"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <p class="ma-0 default_txt">{{ limit }}</p>
                    <v-icon>mdi-chevron-down</v-icon>
                  </v-btn>
                </template>
                <v-card class="popup_radius" outlined elevation="0">
                  <v-list>
                    <v-list-item link @click="changeLimit(10)">
                      <v-list-item-title>10</v-list-item-title>
                    </v-list-item>
                    <v-list-item link @click="changeLimit(50)">
                      <v-list-item-title>50</v-list-item-title>
                    </v-list-item>
                    <v-list-item link @click="changeLimit(100)">
                      <v-list-item-title>100</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-menu>
            </div>
            <div class="col_tollbar">
              <v-select
                outlined
                hide-details
                dense
                :items="optDocument"
                item-text="text"
                item-value="val"
                v-model="tipe_document"
                placeholder="Filter asal document"
                @change="fetchData"
                style="width: 200px"
              >
              </v-select>
            </div>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <div class="mt-3 px-1">
      <v-skeleton-loader
        class="pa-2 elevation-0"
        type="table-tbody"
        v-if="loading"
      ></v-skeleton-loader>
      <v-data-table
        v-if="!loading && dataSet"
        :headers="headers"
        :items="dataSet"
        hide-default-footer
        :page.sync="page"
        :items-per-page="limit"
        class="elevation-0"
        :single-select="false"
      >
        <template v-slot:[`item.layanan`]="{ item }">
          <div class="d-flex text-capitalize">
            {{ item.layanan + " - " + item.pemilik }}
          </div>
        </template>
        <template v-slot:[`item.file`]="{ item }">
          <div
            class="d-flex"
            @click="toFile(item.file)"
            :style="`text-decoration : ${item.file ? 'underline' : ''}; color:${
              item.file ? 'blue' : ''
            }; cursor:${item.file ? 'pointer' : ''}`"
          >
            {{ item.file ? item.file : "Tidak ada file" }}
          </div>
        </template>
        <template v-slot:[`item.deskripsi`]="{ item }">
          <div class="d-flex">
            {{ item.deskripsi }}
          </div>
        </template>
      </v-data-table>
      <div v-if="!loading && dataSet" class="text-center pa-3">
        <v-pagination
          color="#052633"
          v-model="page"
          @input="fetchData"
          :length="last_page"
        ></v-pagination>
      </div>
    </div>
    <div class="pa-2">
      <dialog-small
        :d_small="d_small"
        @refetch="fetchData"
        @close="d_small = false"
        :inventory="inventory"
        :type="d_type"
        :title="d_title"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import bullet from "../../components/Etc/bullet.vue";
import NavbarProfile from "../../components/navbarProfile/navbarProfile.vue";
export default {
  components: { bullet, NavbarProfile },
  name: "listTest",
  computed: {
    ...mapState({}),
  },
  data() {
    return {
      d_small: false,
      d_type: "",
      d_title: "",
      inventory: "",
      line: "",
      menuLimit: false,
      tab: 0,
      loading: true,
      page: 1,
      limit: 10,
      tipe_document: "",
      optDocument: [
        {
          text: "Event",
          val: "event",
        },
        {
          text: "Task Management",
          val: "task",
        },
        {
          text: "All Document",
          val: "",
        },
      ],
      items: [
        {
          text: "Home",
          disabled: false,
          href: "/",
        },
        {
          text: "All Document",
          disabled: true,
          href: "#",
        },
      ],
      headers: [
        { text: "Document", value: "tipe", sortable: false },
        { text: "Layanan", value: "layanan", sortable: false },
        { text: "Step", value: "step", sortable: false },
        {
          text: "File",
          value: "file",
        },
        {
          text: "Deskripsi",
          value: "deskripsi",
          width: "30%",
          sortable: false,
        },
      ],
      klien: null,
      dataSet: null,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    toFile(link) {
      window.open(link, "blank");
    },
    // get list dokumen
    fetchData() {
      this.loading = true;
      let data = {
        path: `sysadmin/document?page[size]=${this.limit}&page[number]=${this.page}&filter[tipe]=${this.tipe_document}`,
      };
      this.$store
        .dispatch("fileData/getData", data)
        .then((data) => {
          this.klien = data.klien;
          this.dataSet = data.data.data;
          this.last_page = data.data.last_page;
          this.total_page = data.data.total;
          this.loading = false;
        })
        .catch((e) => {
          this.$store.dispatch("notify", {
            msg: e.message,
            type: "error",
          });
          this.loading = false;
        });
    },
    // delete list dokumen tapi ngga jadi
    deleteData(item) {
      this.inventory = {
        data: item,
        path: `sysadmin/admin/invoice/delete`,
        deleteType: "Tes Psikologi",
      };
      this.d_type = "delete";
      this.d_title = "Hapus Tes Psikologi";
      this.d_small = true;
      this.line = true;
    },
    changeLimit(val) {
      this.limit = val;
      this.fetchData();
    },
  },
};
</script>

<style scoped>
.unactive {
  background: #cdd0d1;
  color: #9ba0a3 !important;
  text-transform: capitalize;
}
.active {
  border: 1px solid #cdd0d1;
  color: black !important;
  box-sizing: border-box;
  text-transform: capitalize;
}
.v-pagination__item {
  box-shadow: none !important;
}
.v-pagination__navigation {
  box-shadow: none !important;
}
</style>
