var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"pa-4"},[_c('navbar-profile',{attrs:{"items":_vm.items}})],1),_c('div',{staticClass:"px-5"},[_c('v-row',{staticClass:"mt-4",attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{staticClass:"py-2",attrs:{"cols":"12","md":"4"}},[_c('bullet',{attrs:{"title":'List All Document'}})],1),_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-row',{attrs:{"no-gutters":"","align":"center","justify":"end"}},[_c('div',{staticClass:"col_tollbar d-flex align-center"},[_c('h4',{staticClass:"mr-2"},[_vm._v("Show")]),_c('v-menu',{attrs:{"elevation":"0","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-capitalize",attrs:{"min-width":"60px","max-width":"60px","outlined":"","depressed":""}},'v-btn',attrs,false),on),[_c('p',{staticClass:"ma-0 default_txt"},[_vm._v(_vm._s(_vm.limit))]),_c('v-icon',[_vm._v("mdi-chevron-down")])],1)]}}]),model:{value:(_vm.menuLimit),callback:function ($$v) {_vm.menuLimit=$$v},expression:"menuLimit"}},[_c('v-card',{staticClass:"popup_radius",attrs:{"outlined":"","elevation":"0"}},[_c('v-list',[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.changeLimit(10)}}},[_c('v-list-item-title',[_vm._v("10")])],1),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.changeLimit(50)}}},[_c('v-list-item-title',[_vm._v("50")])],1),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.changeLimit(100)}}},[_c('v-list-item-title',[_vm._v("100")])],1)],1)],1)],1)],1),_c('div',{staticClass:"col_tollbar"},[_c('v-select',{staticStyle:{"width":"200px"},attrs:{"outlined":"","hide-details":"","dense":"","items":_vm.optDocument,"item-text":"text","item-value":"val","placeholder":"Filter asal document"},on:{"change":_vm.fetchData},model:{value:(_vm.tipe_document),callback:function ($$v) {_vm.tipe_document=$$v},expression:"tipe_document"}})],1)])],1)],1)],1),_c('div',{staticClass:"mt-3 px-1"},[(_vm.loading)?_c('v-skeleton-loader',{staticClass:"pa-2 elevation-0",attrs:{"type":"table-tbody"}}):_vm._e(),(!_vm.loading && _vm.dataSet)?_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.dataSet,"hide-default-footer":"","page":_vm.page,"items-per-page":_vm.limit,"single-select":false},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"item.layanan",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex text-capitalize"},[_vm._v(" "+_vm._s(item.layanan + " - " + item.pemilik)+" ")])]}},{key:"item.file",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex",style:(("text-decoration : " + (item.file ? 'underline' : '') + "; color:" + (item.file ? 'blue' : '') + "; cursor:" + (item.file ? 'pointer' : ''))),on:{"click":function($event){return _vm.toFile(item.file)}}},[_vm._v(" "+_vm._s(item.file ? item.file : "Tidak ada file")+" ")])]}},{key:"item.deskripsi",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(item.deskripsi)+" ")])]}}],null,true)}):_vm._e(),(!_vm.loading && _vm.dataSet)?_c('div',{staticClass:"text-center pa-3"},[_c('v-pagination',{attrs:{"color":"#052633","length":_vm.last_page},on:{"input":_vm.fetchData},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1):_vm._e()],1),_c('div',{staticClass:"pa-2"},[_c('dialog-small',{attrs:{"d_small":_vm.d_small,"inventory":_vm.inventory,"type":_vm.d_type,"title":_vm.d_title},on:{"refetch":_vm.fetchData,"close":function($event){_vm.d_small = false}}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }